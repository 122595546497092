<div id="record">
  <div class="app-container" [ngClass]="{ 'bg-light': account }">
    <!-- main nav -->
    <nav class="navbar navbar-expand navbar-dark bg-dark bg-opa" *ngIf="account">
      <div class="navbar-nav">
        <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-item nav-link">Home</a>
        <a routerLink="/profile" routerLinkActive="active" class="nav-item nav-link">Perfil</a>
        <a *ngIf="account.role === Role.Admin" routerLink="/stock" routerLinkActive="active" class="nav-item nav-link">Ações</a>
        <a *ngIf="account.role === Role.Admin" routerLink="/admin" routerLinkActive="active" class="nav-item nav-link">Admin</a>
        <a (click)="logout()" routerLink="" class="nav-item nav-link">Logout</a>
      </div>
    </nav>

    <!-- subnav router outlet -->
<!--    <router-outlet name="subnav"></router-outlet>-->

    <!-- global alert -->
    <alert></alert>

    <!-- main router outlet -->
    <router-outlet></router-outlet>
  </div>
</div>
